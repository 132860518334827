import React from 'react'
import '../assets/css/edgeforecaster.css'

const VideoSection = ({
  videoSrcURL,
  videoTitle,
  videoWidth,
  videoHeight,
  ...props
}) => (
  <div className="index_info_left">
    <div className="wrapper">
      <div className="video">
        <iframe
          src={videoSrcURL}
          title={videoTitle}
          height={videoHeight}
          width={videoWidth}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen={true}
        />
      </div>
    </div>
  </div>
)
export default VideoSection
